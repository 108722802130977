import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ProjectListing } from 'components';

const CenterContent = styled.div`
  width: 90%;
  margin: auto;
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    width: ${props => props.theme.breakpoints.l};
  }
`;

const SectionHeader = styled.h1`
  margin-top: 24px;
  margin-bottom: -4px;
  padding: 0;
  text-transform: uppercase;
`;

const Index = ({
  data: {
    projects: { edges: projectsEdges },
  },
}) => (
  <React.Fragment>
    <CenterContent>
      <SectionHeader>Projects</SectionHeader>
    </CenterContent>
    <ProjectListing projectEdges={projectsEdges} />
  </React.Fragment>
);

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    work: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
    projects: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query ProjectsQuery {
    projects: allMdx(sort: { fields: [frontmatter___order], order: ASC }, filter: { fileAbsolutePath: {regex : "\/projects/"} }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            service
            cover {
              childImageSharp {
                fluid(maxWidth: 400, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
